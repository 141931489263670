
<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="500px" transition="dialog-transition" >
      <v-card style="border-radius:12px">
        <v-card-title primary-title>
          <div class="headline mb-0 error--text">Houve um erro</div>
        </v-card-title>
        <v-card-text class="pa-3 px-8">
          <div class="title">
            {{error_msg}}
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <!-- <v-btn flat text color="error">Cancelar</v-btn> -->
          <v-btn text color="primary" @click="dialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  props: {
    error_msg: {
      type: String,
    },
  },
  data() {
    return {
      dialog: true
    }
  },
  methods: {
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  watch: {
    dialog(nv,ov) {
      if (nv){
      } else if (nv == false){
        this.$emit('close')
      }
    }
  },
}
</script>

<style scoped>

</style>